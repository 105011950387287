import { render, staticRenderFns } from "./KimsaSelect.vue?vue&type=template&id=4537119c&scoped=true&"
import script from "./KimsaSelect.vue?vue&type=script&lang=js&"
export * from "./KimsaSelect.vue?vue&type=script&lang=js&"
import style0 from "./KimsaSelect.vue?vue&type=style&index=0&id=4537119c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4537119c",
  null
  
)

export default component.exports