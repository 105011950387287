<template>
    <div class="center-all text-center">
        <div>
            <v-img class="mx-auto" width="180" :src="imgLogoDark"/>
        </div>
        <div class="title-success">
            Datos guardados correctamente
        </div>
        <div class="subtitle-success">
            Iniciemos tu proceso ahora
        </div>
        <v-btn color="secondary" x-large depressed class="normal-btn mt-5" @click="onRedirect">
            Cuestionario
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "RegisterClientSuccessCard",
    methods: {
        onRedirect() {
            const vm = this
            vm.goTo({name: 'client.questionnaire'})
        }
    }
}
</script>

<style scoped>

.center-all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.title-success {
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
    color: var(--v-primary-base);
    margin-top: 32px;
    margin-bottom: 12px;
}

.subtitle-success {
    font-size: 14px;
    line-height: 16px;
    color: #1a1a1a;
}

</style>