<template>
    <master-panel pa0>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" class="mt-3">
                    <div class="title-register">
                        Tu proceso para una cirugía bariátrica comienza aquí.
                    </div>
                    <div class="subtitle-register mt-3">
                        Completa tus datos, responde el cuestionario y
                        recibe tu orden de exámenes para que nuestro
                        equipo evalúe los resultados
                    </div>
                </v-col>
                <v-col cols="12" class="mt-3">
                    <v-form v-model="valid">
                        <v-row>
                            <v-col cols="12" md="6">
                                <kimsa-text-field
                                    label="Nombre"
                                    :rules="allRules.name"
                                    @change="name = $event"
                                    :value="name"
                                    outlined dense
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-text-field
                                    label="e-mail"
                                    :rules="allRules.email"
                                    @change="email = $event"
                                    outlined dense
                                    :value="email"
                                    inputMode="email"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-text-field
                                    label="RUT"
                                    :rules="allRules.rut"
                                    @change="rut = $options.filters.rut($event)"
                                    :value="rut"
                                    outlined dense
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-text-field
                                    label="Teléfono"
                                    :rules="allRules.phone"
                                    @change="phone = $event"
                                    :value="phone"
                                    outlined dense
                                    :prefix="phoneCode"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-select
                                    label="Sexo"
                                    :rules="allRules.gender"
                                    @change="gender = $event"
                                    :value="gender"
                                    :items="genderOptions"
                                    outlined dense no-object
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-date-picker
                                    label="DD / MM / AAAA"
                                    :rules="allRules.birthDate"
                                    @change="birthDate = $event"
                                    :value="birthDate"
                                    outlined dense birth-date-mode
                                    min="1900-01-01"
                                    max="2020-12-31"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-select
                                    label="Region"
                                    :rules="allRules.region"
                                    @change="region = $event"
                                    :value="region"
                                    :items="regionOptions"
                                    outlined dense
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-select
                                    label="Ciudad"
                                    :rules="allRules.city"
                                    @change="city = $event"
                                    :value="city"
                                    :items="cityOptions"
                                    outlined dense no-object
                                    :disabled="!region"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-password-field
                                    label="Contraseña"
                                    :rules="allRules.password"
                                    @change="password = $event"
                                    :value="password"
                                    outlined dense
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-password-field
                                    label="Confirmar contraseña"
                                    :rules="allRules.confirmPassword"
                                    @change="confirmPassword = $event"
                                    :value="confirmPassword"
                                    outlined dense
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" x-large depressed class="normal-btn"
                   :loading="loading" :disabled="!valid" @click="onRegister">
                Siguiente
            </v-btn>
        </template>
    </master-panel>
</template>

<script>

import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaPasswordField from "@/components/common/inputs/KimsaPasswordField";
import {mapActions} from 'vuex'
import {rules} from "@/utils/Rules";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import MasterPanel from "@/components/layouts/MasterPanel";
import httpService from "@/services/httpService";
import {regions} from "@/utils/LocalData";

export default {
    name: "RegisterClientCard",
    components: {
        MasterPanel,
        KimsaSelect,
        KimsaDatePicker,
        KimsaTextField,
        KimsaPasswordField,
    },
    data() {
        return {
            valid: false,
            loading: false,
            name: '',
            email: '',
            rut: '',
            phone: '',
            gender: '',
            birthDate: null,
            region: null,
            city: null,
            password: '',
            confirmPassword: '',

            genderOptions: [],
        }
    },
    computed: {
        allRules() {
            return {
                name: [rules.required, rules.minLength(this.name, 3)],
                email: [rules.required, rules.email],
                rut: [rules.required, rules.rut],
                phone: [rules.required],
                gender: [rules.required],
                birthDate: [rules.required],
                region: [rules.required],
                city: [rules.required],
                password: [rules.required, rules.minLength(this.password, 6)],
                confirmPassword: [rules.required, rules.sameValue(this.confirmPassword, this.password, 'Contraseña')]
            }
        },
        doctorId(vm = this) {
            return vm.$route.params.id || null
        },
        regionOptions() {
            return regions || []
        },
        cityOptions(vm = this) {
            return vm.region?.cities || []
        },
    },
    async mounted() {
        const vm = this
        let response = await httpService.get('genders')
        vm.genderOptions = response.data.genders
    },
    methods: {
        ...mapActions({register: 'auth/registerClient'}),
        async onRegister() {
            const vm = this
            if (!vm.valid) return vm.toast('Datos incorrectos', 'error')

            vm.loading = true
            let payload = {
                doctor_id: vm.doctorId,
                name: vm.name,
                email: vm.email,
                rut: vm.rut.replace(/\./g, '').replace(/-/g, ''),
                phone: vm.phone,
                gender_id: vm.gender,
                birth_date: vm.birthDate,
                region: vm.region.name,
                city: vm.city,
                password: vm.password,
            }

            // console.log('payload', payload)

            let response = await vm.register(payload)
            // console.log('response register', response)

            vm.loading = false
            if (response.error) return vm.toast(response.error, 'error')
            if (response.data.error) return vm.toast('Datos incorrectos', 'error')
            vm.$emit('success')
        },
    },
}
</script>

<style scoped>

.title-register {
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
    color: var(--v-primary-base);
    text-align: center;
}

.subtitle-register {
    font-size: 13px;
    line-height: 15px;
    color: #1a1a1a;
    text-align: center;
}

</style>