<template>
    <v-select
        :color="color"
        :item-color="color"
        v-model="model"
        :label="label"
        :placeholder="placeholder"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :dense="dense"
        :solo="solo"
        :flat="flat"
        :outlined="outlined"
        :clearable="clearable"
        :hide-details="!showDetails"
        :return-object="!noObject"
        :multiple="multiple"
        :readonly="readonly"
        :disabled="disabled"
        :append-icon="appendIcon"
        :prepend-inner-icon="prependInnerIcon"
        :loading="loading"
        :rules="rules"
        @click:clear="$emit('clear')"
        ref="select"
        :menu-props="{offsetY: true}"
    />
</template>

<script>
export default {
    name: "KimsaSelect",
    props: {
        color: {
            type: String,
            default: 'secondary'
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: []
        },
        multiple: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: 'name'
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        noObject: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Number, String, Object, Array],
            default: null
        },
        loading: {
            type: Boolean,
            default: false,
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        appendIcon: {
            type: String,
            default: undefined,
        },
        prependInnerIcon: {
            type: String,
            default: undefined,
        },
        forceValue: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            model: null,
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) vm.model = vm.value
        },
        focus() {
            const vm = this
            vm.$refs.select.focus()
        },
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            if (!this.noObject && typeof val !== 'object') val = null
            this.$emit('change', val)
        },
    }
}
</script>

<style scoped>

>>> .v-input__control {
    background: #FFFFFF;
    border-radius: 12px;
}

</style>