<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
                <register-client-success-card v-if="finished"/>
                <register-client-card @success="onSuccess" v-else/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import RegisterClientCard from "@/components/entrance/RegisterClientCard";
import RegisterClientSuccessCard from "@/components/entrance/RegisterClientSuccessCard";

export default {
    components: {
        RegisterClientSuccessCard,
        RegisterClientCard,
    },
    data() {
        return {
            finished: false,
        }
    },
    methods: {
        onSuccess() {
            const vm = this
            vm.finished = true
        }
    },
}
</script>
